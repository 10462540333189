<template>
  <div class="pieces-popup">
    <h2 class="mb-3">Block Pieces</h2>
    <div v-for="(cassette, idx) of cassettesToSubmit" v-bind:key="cassette.id" class="row mb-0">
      <div class="col-1 ml-5 d-flex justify-content-end">
        <b class="mt-2">{{ blockNumDisplay(cassette.blockNum) }}</b>
      </div>
      <div class="col-4 pl-0">
        <NumberInput
          :noLabel="true"
          v-model="cassette.qtyPieces"
          :ref="'numberInput' + idx"
          :inputAttrs="inputAttrs(cassette)"
          :name="'piecesForBlock' + cassette.blockNum"
        />
      </div>
    </div>
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" class="mb-2" />
  </div>
</template>

<script>
import NumberInput from "./common/NumberInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import { cloneDeep } from "lodash";
import { toLetters } from "@/modules/helpers";
import { mapGetters } from "vuex";
import { SpecimenNumbersEnum } from "@/modules/enums";

export default {
  components: { SubmitCancelRow, NumberInput },
  props: ["cassettes"],
  data() {
    return {
      cassettesToSubmit: []
    };
  },
  mounted() {
    this.cassettesToSubmit = cloneDeep(this.cassettes);
    this.$nextTick(() => {
      this.focusOnFirstField();
    });
  },
  computed: {
    ...mapGetters("accessionStore", ["specimenNumbering"])
  },
  methods: {
    handleSubmit() {
      this.$emit("submitPieces", this.cassettesToSubmit);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    focusOnFirstField() {
      const firstFieldRef = this.$refs.numberInput0[0];
      if (firstFieldRef) {
        firstFieldRef.focus()
        firstFieldRef.selectAll("piecesForBlock1");
      }
    },
    blockNumDisplay(blockNum) {
      if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
        return toLetters(blockNum);
      }
      return blockNum;
    },
    inputAttrs(cassette) {
      return { id: "NumberInput" + cassette.blockNum };
    }
  }
};
</script>

<style lang="scss" scoped>
.pieces-popup {
  width: 95%;
}

.text-input {
  min-height: 40px;
}
</style>

<template>
  <!-- CONTENT -->
  <div class="container">
    <g-code-popup />
    <div ref="pageTitle" class="specimen-section__header">
      <PageTitle>Specimen</PageTitle>

      <icon-button
        class="btn btn-outline-info specimen-section__header-icon"
        @click="toggleAccordion"
        icon="expand-alt"
      />
      <icon-button
        class="btn btn-outline-info specimen-section__header-icon ml-2"
        @click="toggleLock"
        :icon="isUnlocked ? 'lock-open' : 'lock'"
        v-tooltip="'Click to unlock free text editors'"
      />
      <div class="specimen-section__header-btn align-item-center d-flex">
        <loader size="small" v-show="isPending"></loader>
        <button
          v-shortkey="shortkeys.a"
          :disabled="isPending"
          v-if="permissions.CaseSpecimenCreateEdit"
          @click="addSpecimen"
          @shortkey="addSpecimen"
          type="button"
          data-testid="addBtn"
          class="btn btn-outline-primary"
        >
          Add Item
        </button>
        <button
          v-shortkey="shortkeys.f"
          @shortkey="useSpecimenCompleteMode"
          class="btn ml-1 btn-success"
          :disabled="!allSpecimensGrossed || isPending"
          @click="useSpecimenCompleteMode"
          v-show="permissions.CaseFinalizeSlidePrep && isSlidePrep"
        >
          <u>F</u>inalize
        </button>
      </div>
    </div>
    <div ref="specimenView" v-if="accordionView && specimens.length" id="specimen-accordian">
      <div id="accordion">
        <accordion
          v-for="(specimen, index) in specimens"
          :key="index"
          :id="index"
          :isExpanded="selectedIndex === index"
          :title="buildTitle(specimen, protocolOptions, bodyPartOptions)"
          accordionId="accordion"
          @click="setSelected(specimen, index)"
          ref="titleRef"
        >
          <specimen-full
            v-if="selectedIndex === index"
            ref="specimenFull"
            @delete="deleteSpecimen"
            @save="afterSpecimenSave"
            v-model="selectedSpecimen"
            :protocolItems="protocolOptions"
            :bodyParts="bodyPartOptions"
            :isLoading="isPending"
            :isUnlocked="isUnlocked"
          />
        </accordion>
      </div>
    </div>
    <div ref="specimenView" v-else-if="!accordionView && specimens.length" id="specimen-grid">
      <DxDataGrid
        id="specimmenGridContainer"
        key-expr="specimenOrder"
        :data-source="specimens"
        :columns="columns"
        :show-borders="true"
        :selectedRowKeys="selectedSpecimenOrder"
        @row-click="onSelectionChanged"
        @initialized="setGridInstance"
        :hover-state-enabled="true"
        :cacheEnabled="false"
      />
      <div class="mt-4" v-if="selectedSpecimen">
        <div class="row results-header mt-4">
          <h5
            class="d-flex align-items-center"
            v-html="buildTitle(selectedSpecimen, protocolOptions, bodyPartOptions)"
            ref="titleRef"
          ></h5>
        </div>
        <specimen-full
          class="mt-3"
          ref="specimenFull"
          @delete="deleteSpecimen"
          @save="afterSpecimenSave"
          :protocolItems="protocolOptions"
          :bodyParts="bodyPartOptions"
          v-model="selectedSpecimen"
          :isLoading="isPending"
          :isUnlocked="isUnlocked"
        />
      </div>
    </div>
    <div v-else-if="!specimens.length">
      <h5 data-cy="noSpecimens" class="text-center my-5">No specimens found for this accession</h5>
    </div>
  </div>
</template>

<script>
import DropdownApi from "../../services/dropdown";
import Accordion from "@/components/common/Accordion";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { mapState, mapGetters } from "vuex";
import defaultSpecimen from "../../modules/specimen";
import users from "../../services/users";
import SpecimenFull from "@/components/forms/Accession/SpecimenFull.vue";
import {
  getNextSpecimenOrder,
  buildTitle,
  getTextFromHtml,
  altKey,
  sortCaseInsensitive,
  isModalOpen,
  getProstateSites
} from "@/modules/helpers";
import { tap, filter } from "rxjs/operators";
import dialog from "@/modules/dialog";
import { MacrosApi } from "@/services";
import Loader from "@/components/common/Loader.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import IconButton from "../common/IconButton.vue";
import specimenCompleteMode from "@/modules/specimenCompleteMode";
import GCodePopup from "../GCodePopup.vue";
import eventBus, { LAST_SPECIMEN_SAVED, LOCK_EDITORS, UNLOCK_EDITORS } from "@/modules/eventBus";
import { CaseStatusEnum } from "@/modules/enums";
import { cloneDeep } from "lodash";

export default {
  name: "Specimen-List",
  components: {
    DxDataGrid,
    Accordion,
    SpecimenFull,
    Loader,
    PageTitle,
    IconButton,
    GCodePopup
  },
  data() {
    return {
      suggestions: [],
      inputProps: {
        class: "form-control"
      },
      selected: null,
      selection: {
        mode: "single"
      },
      sectionConfigs: {
        icd: {
          onSelected: selected => {
            this.selected = selected.item;
          }
        }
      },
      isAdding: false,
      protocolId_old: null,
      userSearch: users.searchStore,
      timeout: null,
      isPending: false,
      protocolOptions: [],
      defaultSpecimen: defaultSpecimen,
      test: "",
      focusedRowIndex: undefined,
      selectedSpecimen: null,
      selectedIndex: null,
      specimenGrid: null,
      isUnlocked: false,
      routeLeaveCalled: false,
      autoAddedSpecimen: {},
      shortkeys: {
        a: altKey("a"),
        i: altKey("i")
      }
    };
  },
  subscriptions() {
    const labPrefix = this.$watchAsObservable("caseDetails.labPrefix", { immediate: true }).pipe(
      filter(({ newValue }) => newValue !== null),
      tap(({ newValue }) => {
        DropdownApi.getProtocol(newValue).then(res => {
          this.protocolOptions = sortCaseInsensitive(res) || [];
          this.specimens.forEach(specimen => {
            if (specimen.protocolId) {
              const targetProtocol = this.protocolOptions.find(e => e.id === specimen.protocolId);
              if (!targetProtocol) {
                MacrosApi.getMacroDetails(specimen.protocolId).then(res => {
                  const disabledProtocolItem = {
                    displayName: res.macroName,
                    id: res.macroId,
                    disabled: true
                  };
                  if (!this.protocolOptions.filter(e => e.id === disabledProtocolItem.id).length) {
                    this.protocolOptions.push(disabledProtocolItem);
                  }
                });
              }
            }
          });
        });
      })
    );
    return {
      labPrefix
    };
  },
  created() {
    this.$store.dispatch("dropdowns/getBodyParts");
  },
  mounted() {
    if (this.specimens.length && !this.selectedSpecimen) {
      if (this.specimenToLoad) {
        const specimenIndex = this.specimens
          .map(e => e.specimenOrder)
          .indexOf(this.specimenToLoad.toUpperCase());
        if (specimenIndex > -1) {
          this.selectedSpecimen = this.specimens[specimenIndex];
          this.selectedIndex = specimenIndex;
        } else {
          window.notify(`Could not find Specimen "${this.specimenToLoad}"`, "error");
          this.selectedSpecimen = this.specimens[0];
          this.selectedIndex = 0;
        }
        this.$store.commit("accessionStore/setSpecimenToLoad", null);
      } else {
        this.selectedSpecimen = this.specimens[0];
        this.selectedIndex = 0;
      }
    }
    if (
      !this.specimens.length &&
      this.accessionMode === "specimen" &&
      this.permissions.CaseSpecimenCreateEdit
    ) {
      this.addSpecimen();
      this.$nextTick(() => {
        this.autoAddedSpecimen = this.specimens[0];
      });
    }
  },
  watch: {
    selectedSpecimen: {
      deep: true,
      handler(nv) {
        if (!nv) {
          if (this.$refs.pageTitle?.scrollIntoView) {
            this.$refs.pageTitle.scrollIntoView({
              behavior: "smooth",
              inline: "nearest",
              block: "end"
            });
          }
        } else {
          this.$nextTick(() => {
            if (this.$refs.titleRef) {
              this.$refs.titleRef.scrollIntoView({
                inline: "nearest",
                block: "start",
                behavior: "smooth"
              });
              this.$nextTick(() => {
                this.$refs.specimenFull.handleFocus();
              });
            }
          });
        }
      }
    },
    specimens: {
      handler(nv, ov) {
        if (this.hasProstateSpecimen && nv && ov && nv.length > 6 !== ov.length > 6) {
          this.handleProstateSiteChange(nv, ov);
        }
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      token: state => state.token,
      currentLab: state => state.currentLab,
      caseDetails: state => state.accessionStore.caseDetails,
      editType: state => state.accessionStore.editType,
      reasonForChange: state => state.accessionStore.reasonForChange,
      specimens: state => state.accessionStore.specimens,
      sessionDetails: state => state.sessionDetails,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      lastUsedSite: state => state.sessionDetails.lastUsedSite,
      lastUsedProtocol: state => state.sessionDetails.lastUsedProtocol,
      DefaultLastUsedSite: state => state.labSettings.DefaultLastUsedSite,
      DefaultLastProtocolUsed: state => state.labSettings.DefaultLastProtocolUsed,
      ClinicalIcdBrowse: state => state.labSettings.ClinicalIcdBrowse,
      casePrefix: state => state.accessionStore.casePrefix,
      accessionMode: state => state.applicationSettings.accessionMode,
      textEditors: state => state.applicationSettings.textEditors,
      bodyPartOptions: state => state.dropdowns.bodyParts,
      specimenToLoad: state => state.accessionStore.specimenToLoad,
      caseOrders: state => state.accessionStore.caseOrders
    }),
    ...mapGetters(["permissions"]),
    ...mapGetters("accessionStore", ["specimenNumbering"]),
    accordionView: {
      get() {
        return this.$store.state.applicationSettings.accordionView;
      },
      set(value) {
        this.$store.commit("applicationSettings/setAccordionView", value);
        return value;
      }
    },
    columns() {
      return [
        {
          dataField: "specimenOrder",
          caption: "Specimen"
        },
        {
          caption: "Protocol",
          dataField: "protocolId",
          dataType: "number",
          lookup: {
            dataSource: this.protocolOptions,
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        {
          caption: "Site",
          calculateCellValue: this.calcSite
        },
        {
          caption: "Blocks",
          dataField: "blockNum",
          type: "number",
          calculateCellValue: data => {
            const blockCount = data.blockNum || data.cassettes?.length;
            if (blockCount === 1 && this.caseOrders?.length) {
              const ordersForSpecimen = this.caseOrders.filter(e => e.specimenId === data.id);
              if (ordersForSpecimen.length) {
                for (const order of ordersForSpecimen) {
                  if (!order?.noBlocks) {
                    return 1;
                  }
                  return 0;
                }
              }
            }
            return data && blockCount;
          }
        },
        {
          caption: "Slides",
          dataField: "numberOfSlides",
          dataType: "number",
          calculateCellValue: data => {
            const blockCount = data.blockNum || data.cassettes?.length;
            const { numberOfSlides } = data;
            if (blockCount === 1 && this.caseOrders?.length) {
              const ordersForSpecimen = this.caseOrders.filter(e => e.specimenId === data.id);
              if (ordersForSpecimen.length) {
                for (const order of ordersForSpecimen) {
                  if (!order?.noBlocks) {
                    return numberOfSlides;
                  }
                  return 0;
                }
              }
            }
            return numberOfSlides;
          }
        }
      ];
    },
    selectedSpecimenOrder() {
      if (this.selectedSpecimen) {
        return [this.selectedSpecimen.specimenOrder];
      }
      return [];
    },
    mappedProtocol() {
      return this.protocolOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    mappedBodyPart() {
      return this.bodyPartOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    isSlidePrep() {
      const allSpecimensWithIds = this.specimens.filter(e => !!e?.id);
      const isNotSignedOrReported = ![
        CaseStatusEnum.Signed,
        CaseStatusEnum.Reported,
        CaseStatusEnum.ReReleased,
        CaseStatusEnum.ReportedPrelim
      ].includes(this.caseDetails.status);
      return (
        this.casePrefix?.caseCompletionModeId &&
        allSpecimensWithIds?.length &&
        isNotSignedOrReported
      );
    },
    allSpecimensGrossed() {
      if (this.casePrefix?.grossIsRequired === false) {
        return true;
      }
      const allSpecimensWithGross = this.specimens.filter(
        specimen => specimen?.gross && getTextFromHtml(specimen.gross)
      );
      return allSpecimensWithGross.length === this.specimens.length;
    },
    prostateProtocolId() {
      for (const specimen of this.specimens) {
        const protocol = this.protocolOptions.find(e => e.id === specimen.protocolId);
        if (protocol?.isProstate) {
          return protocol.id;
        }
      }
      return null;
    },
    hasProstateSpecimen() {
      for (const specimen of this.specimens) {
        const protocol = this.protocolOptions.find(e => e.id === specimen.protocolId);
        if (protocol?.isProstate) {
          return true;
        }
      }
      return false;
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.routeLeaveCalled) {
      return;
    }
    this.routeLeaveCalled = true;
    let specClear = await this.checkSpecimens();
    if (!specClear) {
      this.routeLeaveCalled = false;
      return;
    }
    if (to?.name !== "SpecimenResults") {
      this.$store.commit("accessionStore/setCurrentSpecimen", {});
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.routeLeaveCalled) {
      return;
    }
    this.routeLeaveCalled = true;
    let specClear = await this.checkSpecimens();
    if (!specClear) {
      this.routeLeaveCalled = false;
      return;
    }
    next();
  },
  methods: {
    setGridInstance(event) {
      this.specimenGrid = event.component;
      this.handleRenderGrid(event);
    },
    useSpecimenCompleteMode() {
      if (isModalOpen()) {
        return;
      }
      this.isPending = true;
      this.caseDetails.specimens = this.specimens;
      this.finalizeSpecimen(this.caseDetails.specimens);
    },
    async finalizeSpecimen(specimens) {
      this.caseDetails.specimens = specimens;
      try {
        await specimenCompleteMode(this.casePrefix, this.caseDetails);
        await this.$store.dispatch("accessionStore/getCaseHeader", this.caseDetails.caseId);
        await this.$store.dispatch("accessionStore/getCaseCounts", this.caseDetails.caseId);
        await this.$store.dispatch("accessionStore/getCaseDetails", this.caseDetails.caseId);
        await this.$store.dispatch("accessionStore/getCaseSpecimens", this.caseDetails.caseId);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isPending = false;
      }
    },
    handleRenderGrid({ component }) {
      const rows = component.getVisibleRows();
      if (rows?.length && !this.selectedSpecimen) {
        const specimens = rows.map(e => e.data);
        this.setSelected(specimens[0], 0);
      }
    },
    getSpecimenRichText(specimen) {
      return {
        gross: specimen.gross ? getTextFromHtml(specimen.gross) : "",
        clinical: specimen.clinical ? getTextFromHtml(specimen.clinical) : ""
      };
    },

    onSelectionChanged(e) {
      const { rowIndex, data } = e;
      this.setSelected(data, rowIndex);
    },
    async setSelected(specimen, index) {
      if (this.selectedSpecimen) {
        const originalSpecimen = {
          protocolId: this.selectedSpecimen.protocolId,
          site: this.selectedSpecimen.site,
          bodyPartId: this.selectedSpecimen.bodyPartId,
          clinicalICDCode: this.selectedSpecimen.clinicalICDCode,
          grosserUserId: this.selectedSpecimen.grosserUserId,
          cutterUserId: this.selectedSpecimen.cutterUserId,
          ...this.getSpecimenRichText(this.selectedSpecimen)
        };
        const currentSpecimen = {
          protocolId: this.currentSpecimen.protocolId,
          site: this.currentSpecimen.site,
          bodyPartId: this.currentSpecimen.bodyPartId,
          clinicalICDCode: this.currentSpecimen.clinicalICDCode,
          grosserUserId: this.currentSpecimen.grosserUserId,
          cutterUserId: this.currentSpecimen.cutterUserId,
          ...this.getSpecimenRichText(this.currentSpecimen)
        };
        const isFormDirty = JSON.stringify(originalSpecimen) !== JSON.stringify(currentSpecimen);
        if (isFormDirty) {
          const confirm = await dialog(
            "You may have unsaved data. Would you like to keep it before continuing?",
            "IntelliPath Pro",
            "Yes",
            "No",
            false
          ).show();
          if (confirm) {
            if (!this.currentSpecimen.site) {
              window.notify("Data missing please check your input.", "warning");
              return;
            }
            if (this.currentSpecimen.id) {
              await this.$store.dispatch("accessionStore/updateCaseSpecimen", this.currentSpecimen);
            } else {
              await this.$store.dispatch("accessionStore/insertCaseSpecimen", this.currentSpecimen);
            }
          }
        }
      }

      if (index === this.selectedIndex) {
        this.selectedSpecimen = null;
        this.selectedIndex = null;
      } else {
        this.selectedSpecimen = specimen;
        this.selectedIndex = index;
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item;
    },
    toggleAccordion() {
      if (!this.accordionView && this.selectedSpecimenOrder.length) {
        const index = this.specimenGrid.getRowIndexByKey(this.selectedSpecimenOrder[0]);
        this.selectedIndex = index;
      }
      this.accordionView = !this.accordionView;
    },
    buildTitle,
    async addSpecimen() {
      if (isModalOpen()) {
        return;
      }
      this.isPending = true;
      let protocolId = this.DefaultLastProtocolUsed ? this.lastUsedProtocol : null;
      if (this.prostateProtocolId) {
        protocolId = this.prostateProtocolId;
      }
      const newSpecimen = {
        ...this.defaultSpecimen,
        site: this.DefaultLastUsedSite && !this.prostateProtocolId ? this.lastUsedSite : "",
        protocolId,
        caseId: this.$route.params.caseId,
        gross: "",
        clinical: "",
        labId: this.currentLab,
        newSpecimen: true,
        numberOfBlocks: 0,
        specimenOrder: getNextSpecimenOrder(this.specimens, this.specimenNumbering)
      };
      if (this.labPrefix.caseCompletionModeId === 2) {
        const defaultPrefixSite = this.labPrefix.site;
        if (defaultPrefixSite) {
          newSpecimen.site = defaultPrefixSite;
        }
        if (this.labPrefix.protocolId) {
          const defaultPrefixProtocol = await MacrosApi.getMacroDetails(this.labPrefix.protocolId);
          newSpecimen.protocolId = this.labPrefix.protocolId;
          if (defaultPrefixProtocol?.gross && getTextFromHtml(defaultPrefixProtocol.gross)) {
            newSpecimen.gross = defaultPrefixProtocol?.gross;
          }
        }
      }
      const originalSpecimens = cloneDeep(this.specimens);
      this.$store.commit("accessionStore/setCaseSpecimens", [...this.specimens, newSpecimen]);
      if (this.hasProstateSpecimen && originalSpecimens.length !== 6) {
        this.handleProstateSiteChange(this.specimens, originalSpecimens);
      }
      this.selectedSpecimen = null;
      this.selectedIndex = null;
      this.$nextTick(() => {
        const index = this.specimens.length - 1;
        this.setSelected(this.specimens[index], index);
      });
      this.isPending = false;
    },
    deleteSpecimen() {
      this.selectedSpecimen = null;
    },

    afterSpecimenSave() {
      window.notify("Specimen Updated", "success", 2000, { at: "center", of: "#navbar" });
      let nextSpecIndex;
      //Toggle current form
      //Checks if there is a next specimen
      if (this.accordionView) {
        if (this.selectedIndex !== this.specimens.length - 1) {
          nextSpecIndex = this.selectedIndex + 1;
        }
      } else {
        const currentIndex = this.specimenGrid.getRowIndexByKey(this.selectedSpecimenOrder[0]);
        if (currentIndex !== this.specimens.length - 1) {
          nextSpecIndex = currentIndex + 1;
        }
      }

      //If there is a next index
      if (nextSpecIndex) {
        this.selectedIndex = nextSpecIndex;
        this.selectedSpecimen = this.specimens[nextSpecIndex];

        //Toggle next specimen
      } else {
        this.selectedIndex = null;
        this.selectedSpecimen = null;
        eventBus.$emit(LAST_SPECIMEN_SAVED);
        this.handleViewPathReport();
      }
      this.$store.dispatch("accessionStore/getCaseHeader", this.caseDetails.caseId);
      this.$store.dispatch("accessionStore/getCaseDetails", this.caseDetails.caseId);
      this.$store.dispatch("accessionStore/getCaseCounts", this.caseDetails.caseId);
    },
    calcProtocol(data) {
      let protocol = this.mappedProtocol[data.protocolId] || "";
      return protocol;
    },
    calcSite(data) {
      let bodyPart = this.mappedBodyPart[data.bodyPartId]
        ? this.mappedBodyPart[data.bodyPartId] + ", "
        : "";
      let site = data.site ? data.site : "";
      return [bodyPart, site].join("");
    },
    handleError(error) {
      this.isSubmitting = false;
      if (error.response?.data) {
        if (error.response.data?.message) {
          return window.notify(error.response.data.message, "error");
        }
        const { errors } = error.response.data;
        if (errors) {
          const errorHTML = `<ul>
                <li>${Object.keys(errors).map(e => {
                  return `${e}: ${errors[e].join("<br>")}`;
                })}</li>
              </ul>`;
          return window.alert(errorHTML);
        }
        if (typeof error.response.data === "string") {
          return window.alert(error.response.data, "error");
        }
        return window.notify("Error occurred.", "error");
      }
      if (error.message) {
        return window.alert(error.message, "error");
      }
      window.notify("Error occurred.", "error");
    },
    toggleLock() {
      if (this.isUnlocked) {
        this.$store.dispatch("applicationSettings/setUserSettings", {
          textEditors: this.textEditors
        });
        eventBus.$emit(LOCK_EDITORS);
      } else {
        eventBus.$emit(UNLOCK_EDITORS);
      }
      this.isUnlocked = !this.isUnlocked;
    },
    async checkSpecimens() {
      if (
        this.autoAddedSpecimen &&
        this.specimens.length === 1 &&
        JSON.stringify(this.specimens[0]) === JSON.stringify(this.autoAddedSpecimen)
      ) {
        await this.$store.dispatch("accessionStore/clearGhostSpecimens");
        return true;
      }
      if (this.selectedSpecimen) {
        const originalSpecimen = {
          protocolId: this.selectedSpecimen.protocolId,
          site: this.selectedSpecimen.site,
          bodyPartId: this.selectedSpecimen.bodyPartId,
          clinicalICDCode: this.selectedSpecimen.clinicalICDCode,
          grosserUserId: this.selectedSpecimen.grosserUserId,
          cutterUserId: this.selectedSpecimen.cutterUserId,
          ...this.getSpecimenRichText(this.selectedSpecimen)
        };
        const currentSpecimen = {
          protocolId: this.currentSpecimen.protocolId,
          site: this.currentSpecimen.site,
          bodyPartId: this.currentSpecimen.bodyPartId,
          clinicalICDCode: this.currentSpecimen.clinicalICDCode,
          grosserUserId: this.currentSpecimen.grosserUserId,
          cutterUserId: this.currentSpecimen.cutterUserId,
          ...this.getSpecimenRichText(this.currentSpecimen)
        };
        const isFormDirty = JSON.stringify(originalSpecimen) !== JSON.stringify(currentSpecimen);
        if (isFormDirty && this.token) {
          const confirm = await dialog(
            "You may have unsaved data. Unsaved will be lost if you proceed.<br> Are you sure?",
            "IntelliPath Pro",
            "Yes",
            "No"
          ).show();
          if (!confirm) {
            return false;
          }
        }
        const specimensWithId = this.specimens.filter(e => e?.id);
        if (specimensWithId.length !== this.specimens.length) {
          const confirm = await dialog(
            "You may have unsaved data. Unsaved will be lost if you proceed.<br> Are you sure?",
            "IntelliPath Pro",
            "Yes",
            "No"
          ).show();
          if (!confirm) {
            return false;
          } else {
            await this.$store.dispatch("accessionStore/clearGhostSpecimens");
          }
        }
      }
      return true;
    },
    handleViewPathReport() {
      const { caseId } = this.$route.params;
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    handleProstateSiteChange(nv, ov) {
      let specimensToUpdate = [];
      const oldSpecimens = ov.map(e => {
        return { site: e.site, specimenOrder: e.specimenOrder };
      });
      const updatedNewSpecimens = getProstateSites(nv, this.prostateProtocolId);
      this.$store.commit("accessionStore/setCaseSpecimens", updatedNewSpecimens);
      for (const specimen of updatedNewSpecimens) {
        const oldSpecimen = oldSpecimens.find(e => e.specimenOrder === specimen.specimenOrder);
        if (
          oldSpecimen &&
          specimen.id &&
          (specimen.site !== oldSpecimen.site || oldSpecimen.protocolId !== this.prostateProtocolId)
        ) {
          specimensToUpdate.push(specimen);
        }
      }
      if (specimensToUpdate.length) {
        this.$store.dispatch("accessionStore/updateProstateSpecimenSites", specimensToUpdate);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

::v-deep span.string {
  color: green;
}

::v-deep .number {
  color: darkorange;
}

::v-deep .boolean {
  color: blue;
}

::v-deep .null {
  color: magenta;
}

::v-deep .key {
  color: red !important;
}

.layout-container {
  width: 100%;
}

.layout-content {
  display: flex;
  margin-top: 20px;
}

.content-section {
  background: $white;
}

.multiselect__single {
  background: unset;
  color: unset;
  padding: unset;
  width: unset;
}

.specimen-section {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-weight: 400;
  }

  &__header-icon {
    color: $primary;
    margin-left: 30px;
    cursor: pointer;
  }

  &__header-btn {
    margin-left: auto !important;
  }
}
/* 
.quick-links-section {
  width: 298px;
  background: $white;
  border-radius: 3px;
  & > h4 {
    text-align: center;
    margin-top: 0.5rem;
  }
} */

::v-deep .small-input {
  margin-left: 20px;
  width: 80px;
  min-height: auto !important;
}

.tag-btn {
  padding: 0px;
  margin: 0px;
}

.icd-col {
  padding-top: 2px;
}
.icd-select {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.custom__icdtag {
  border: white solid 1px;
}

.icd-select >>> .multiselect__content-wrapper {
  width: 500px !important;
}
.results-header {
  background: #e6e6e6;
  padding: 5px 25px;
  margin: 0px auto;
}
</style>

<template>
  <div>
    <h2>Change Block Count</h2>
    <b>Current block count: {{ originalBlockCount }}</b>
    <TextInput label="New Block Count" v-focus v-model="newBlockCount" ref="inputBox" />
    <SubmitCancelRow @cancel="handleCancel" @submit="handleSubmit" />
  </div>
</template>

<script>
import { handleErrors } from "@/modules/handleErrors";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import TextInput from "./common/TextInput.vue";

export default {
  props: ["specimenId", "originalBlockCount"],
  components: { TextInput, SubmitCancelRow },
  data() {
    return {
      newBlockCount: null
    };
  },
  mounted() {
    this.selectAll();
  },
  methods: {
    selectAll() {
      this.newBlockCount = this.originalBlockCount.toString();
      this.$nextTick(() => {
        this.$refs.inputBox.selectAll();
      });
    },
    handleCancel() {
      this.$emit("close");
    },
    async handleSubmit() {
      try {
        this.$emit("changeBlockCount", this.newBlockCount);
      } catch (error) {
        handleErrors(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
